import { Fade } from "react-reveal";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import GalleryEvent1 from "./GalleryEvent1";
import GalleryEvent2 from "./GalleryEvent2";
import GalleryEvent3 from "./GalleryEvent3";
import GalleryEvent4 from "./GalleryEvent4";
import GalleryOpening from "./GalleryOpening";

function GalleryContest() {
  return (
    <>
    <Nav/>
    <section id="poster" className="bg-[#232323] border-b-[4px] border-[#EF901C] pt-4">
      <GalleryOpening/>
      <GalleryEvent1/>
      <GalleryEvent2/>
      <GalleryEvent3/>
      <GalleryEvent4/>
    </section>
    <Footer/>
    </>
  )
}

export default GalleryContest;