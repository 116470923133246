import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './pages/ErrorPage';
import Hero from './pages/homepage/Hero';
import FutureSoldiers from './pages/homepage/FutureSoldiers';
import GearArmy from './pages/homepage/GearArmy';
import MetaQuest from './pages/homepage/MetaQuest';
import MainContest from './pages/contest/MainContent';
import GalleryContest from './pages/contest/Gallery';
import Contestant from './pages/contest/Contestant';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Hero />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/stride-vr",
    element: <Hero />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/stride-vr/overview",
    element: <FutureSoldiers />,
  },
  {
    path: "/stride-vr/feature",
    element: <GearArmy />,
  },
  {
    path: "/stride-vr/accessories",
    element: <MetaQuest />,
  },
  {
    path: "/contest",
    element: <MainContest />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contest/contestants",
    element: <Contestant />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contest/gallery",
    element: <GalleryContest />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
