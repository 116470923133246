import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Nav() {
  const navs = [
    {
      'link': '',
      'title': 'Homepage',
    },
    {
      'link': 'overview',
      'title': 'Overview',
    },
    {
      'link': 'feature',
      'title': 'Feature',
    },
    {
      'link': 'accessories',
      'title': 'Accessories',
    },
  ];

  const navs2 = [
    {
      'link': '',
      'title': 'Poster',
    },
    {
      'link': 'contestants',
      'title': 'Contestants',
    },
    {
      'link': 'gallery',
      'title': 'Gallery',
    },
  ];

  const location = useLocation()
  console.log(location);
  const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <nav className="fixed w-full z-30 top-0 left-0">
      <div className="bg-amber-500 dark:bg-gray-900 ">
        <div className="max-w-screen-xl mx-auto sm:px-4 sm:pt-4 sm:pb-2 px-4 pt-5 pb-2  relative">
          <div className="lg:mx-auto mx-3">
          <h2 className="text-white lg:text-4xl sm:text-4xl text-2xl font-bold lg:text-center sm:text-center text-right">STRIDE Virtual Reality</h2>
          <div className="flex lg:justify-center justify-end space-x-2 pt-2">
          <Link to={`/stride-vr/`} className={`inline-block text-white font-bold ${location.pathname.includes("stride-vr") || location.pathname === '/' ? 'bg-[#E06D1D] px-3 rounded-full py-0.5' : ''}`}>STRIDE VR</Link>
          <Link to={`/contest/`} className={`inline-block text-white font-bold ${location.pathname.includes("contest")? 'bg-[#E06D1D] px-3 rounded-full py-0.5' : ''}`}>Contest</Link>
          </div>
          </div>
          <div className="absolute top-0 lg:left-24 sm:left-20 left-2 bg-[#E06D1D] rounded-b-full lg:pt-7 sm:pt-8 pt-2 lg:px-3 px-2 lg:pb-3 pb-2">
            <div className="rounded-full bg-white lg:p-4 p-2">
              <img src={`/stride-logo.png`} alt="Logo Stride" width="40px"/>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#131313] w-full">
        <div className="max-w-screen-xl mx-auto sm:py-3 py-3">
          <ul
            className="flex p-0 lg:pl-0 pl-3 mt-0 font-medium rounded-lg flex-row md:space-x-5 space-x-0 lg:justify-center sm:justify-center justify-center">
            {location.pathname.includes("stride-vr") || location.pathname === '/' ? (
              navs.map((nav, index) => (
                <li key={`${index}`}>
                  <Link to={`/stride-vr/${nav.link}`} onClick={() => handleClickScroll(nav.link)} className={`text-white sm:px-5 px-3 pt-1 pb-1.5 hover:bg-[#E06D1D] ${location.pathname === '/stride-vr/'+nav.link ? 'bg-[#E06D1D]' : ''} rounded-full`} aria-current="page">{nav.title}</Link>
                </li>
              ))
            ) : (
              navs2.map((nav2, index) => (
                <li key={`${index}`}>
                  <Link to={`/contest/${nav2.link}`} onClick={() => handleClickScroll(nav2.link)} className={`text-white sm:px-5 px-3 pt-1 pb-1.5 hover:bg-[#E06D1D] ${location.pathname === '/contest/'+nav2.link ? 'bg-[#E06D1D]' : ''} rounded-full`} aria-current="page">{nav2.title}</Link>
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Nav;