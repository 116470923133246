import { useEffect } from "react";
import { Fade } from "react-reveal";

function Footer() {
  let absolute = false;
  let root = document.getElementById('root');
  root.classList.remove('min-h-[99vh]');
  const windowHeight = window.innerHeight;
  console.log(root.offsetHeight, windowHeight);

  useEffect(() => {
    if ((root.offsetHeight < windowHeight) && (window.innerWidth < 460)) {
      absolute = true;
      root.classList.add('min-h-[99vh]');
    }else{
      absolute = false;
      root.classList.remove('min-h-[99vh]');
    }
  }, [])
  return (
    <>
      <footer id="footer" className={`w-full ${absolute ? 'absolute bottom-0' : ''}`}>
        <div className="bg-[#131313] lg:py-10 py-4">
          <div className="max-w-screen-xl mx-auto grid grid-cols-12 ">
          <div className="lg:col-span-2 col-span-3"></div>
          <div className="lg:col-span-10 col-span-9">
            <h4 className="text-white lg:text-base text-sm font-semibold">
            Science & Technology Research Institute For Defence (STRIDE) STRIDE Main Complex, <br /> Taman Bukit Mewah Fasa 9, 43000 Kajang, Selangor Darul Ehsan, Malaysia <br />Tel : +6019-3078993 Fax: +603-8733 5979
            </h4>
          </div>
          </div>
        </div>
        <div className="bg-[#EF901C] py-2">
          <div className="max-w-screen-xl mx-auto grid grid-cols-12 relative">
            <div className="absolute -bottom-2 lg:left-24 sm:left-20 left-2 bg-[#E06D1D] rounded-t-full lg:pt-3 lg:px-3 pt-2 px-2 lg:pb-20 sm:pb-16 pb-20 z-30">
              <div className="rounded-full bg-white lg:p-4 p-2">
                <img src={`/stride-logo.png`} alt="Logo Stride" width="40px"/>
              </div>
            </div>
            <div className="lg:col-span-2 col-span-3"></div>
            <div className="lg:col-span-10 col-span-9">
              <h4 className="text-white font-semibold">STRIDE Future Soldier VR</h4>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;