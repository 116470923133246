export const slides = [
  {
    title: "Sekolah Menengah Sains Rambu-(1).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(1).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(2).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(2).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(3).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(3).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(4).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(4).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(5).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(5).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(6).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(6).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(7).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(7).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(8).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(8).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(9).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(9).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(10).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(10).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(11).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(11).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(12).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(12).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(13).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(13).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(14).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(14).jpg'
  },
  {
    title: "Sekolah Menengah Sains Rambu-(15).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/1_jpg/julai-(15).jpg'
  },
];

export const thumbnail = [
  {
    src: '/gallery/1_jpg/julai-(1).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/1_jpg/julai-(2).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/1_jpg/julai-(3).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/1_jpg/julai-(4).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/1_jpg/julai-(5).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/1_jpg/julai-(6).jpg',
    width: 1280,
    height: 720,
  },
]

// 358105103_699326665572894_4811382217691715056_n.jpg
// 358113242_699326072239620_286075890271650107_n.jpg
// 358143904_699326435572917_727428757090396019_n.jpg
// 358710932_699327465572814_5169080339128119897_n.jpg
// 356412357_699325938906300_4858189361365843278_n.jpg
// 356421570_699327102239517_5150627079101387842_n.jpg
// 356666993_699326598906234_6828509268106553696_n.jpg
// 356885515_699327358906158_8711636543274158893_n.jpg
// 356935727_699327152239512_691440578781671208_n.jpg
// 357063359_699326242239603_7975807719404030595_n.jpg
// 357094537_699326658906228_4338353531443769950_n.jpg
// 357125215_699327345572826_3461390635607978980_n.jpg
// 357428770_699327218906172_1697177756980225684_n.jpg
// 357508730_699326588906235_1249450784493218265_n.jpg
// 357754142_699327235572837_6131000517428290682_n.jpg