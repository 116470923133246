export const slides = [
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(1).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(1).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(2).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(2).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(3).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(3).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(4).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(4).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(5).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(5).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(6).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(6).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(7).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(7).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(8).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(8).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(9).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(9).jpg'
  },
  {
    title: "Sekolah Menengah Kebangsaan Durian Tunggal-(10).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/2_jpg/august-(10).jpg'
  },
];

export const thumbnail = [
  {
    src: '/gallery/2_jpg/august-(1).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/2_jpg/august-(2).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/2_jpg/august-(3).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/2_jpg/august-(4).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/2_jpg/august-(5).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/2_jpg/august-(6).jpg',
    width: 1280,
    height: 720,
  },
];