import { Fade } from "react-reveal";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

function MainContest() {
  return (
    <>
    <Nav/>
    <section id="poster" className="bg-[#232323] border-b-[4px] border-[#EF901C] pt-24">
      <div className="max-w-screen-lg mx-auto pt-20 pb-16 lg:px-16 sm:px-16 px-6 text-center lg:grid lg:grid-cols-2 lg:gap-3 lg:space-y-0 space-y-3">
        <img src={`/poster-1.png`} alt="Poster Stride Contest 1" className="w-full"/>
        <img src={`/poster-2.png`} alt="Poster Stride Contest 2" className="w-full"/>
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default MainContest;