export const slides = [
  {
    type: "video",
    title: "Video Opening Event Stride",
    description:'',
    width: 1280,
    height: 720,
    poster:
      "/thumbnail/stride-video.png",
    sources: [
      {
        src: "/gallery/stride-video.mp4",
        type: "video/mp4",
      },
    ],
  },
  {
    title: "Opening Ceremony-(1).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(1).jpg'
  },
  {
    title: "Opening Ceremony-(2).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(2).jpg'
  },
  {
    title: "Opening Ceremony-(3).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(3).jpg'
  },
  {
    title: "Opening Ceremony-(4).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(4).jpg'
  },
  {
    title: "Opening Ceremony-(5).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(5).jpg'
  },
  {
    title: "Opening Ceremony-(6).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(6).jpg'
  },
  {
    title: "Opening Ceremony-(7).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(7).jpg'
  },
  {
    title: "Opening Ceremony-(8).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(8).jpg'
  },
  {
    title: "Opening Ceremony-(9).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/opening/open-(9).jpg'
  },
];

export const thumbnail = [
  {
    src: '/thumbnail/stride-video.png',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/opening/open-(1).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/opening/open-(2).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/opening/open-(3).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/opening/open-(4).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/opening/open-(5).jpg',
    width: 1280,
    height: 720,
  },
]