import { Fade } from "react-reveal";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { Table } from 'flowbite-react';

function Contestant() {
  return (
    <>
    <Nav/>
    <section id="poster" className="bg-[#232323] border-b-[4px] border-[#EF901C] pt-36 overflow-x-auto px-3 max-w-[100vw]">
    <h3 className="text-white text-center mt-12">Sila muat turun jadual peserta terperinci di sini: <a className="text-[#EF901C]" href="https://docs.google.com/document/d/1P8Kb4ZIx2LR4MCzCbWRvDRnBVgylkZFX/edit?usp=sharing&ouid=111853424986330305979&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">Senarai Penyertaan.docx</a></h3>
      <Table striped className="max-w-7xl mx-auto mt-4 mb-4 overflow-x-auto">
        <Table.Head className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <Table.HeadCell>
            BIL
          </Table.HeadCell>
          <Table.HeadCell>
            NAMA SEKOLAH
          </Table.HeadCell>
          <Table.HeadCell>
            NAMA KUMPULAN
          </Table.HeadCell>
          <Table.HeadCell>
            TAJUK PROJEK
          </Table.HeadCell>
          <Table.HeadCell>
            NAMA GURU
          </Table.HeadCell>
          <Table.HeadCell>
            AHLI KUMPULAN
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          <Table.Row className="bg-white">
            <Table.Cell>
            1
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Durian Tunggal

            
            </Table.Cell>
            <Table.Cell>
            DUTASS DELTA
            </Table.Cell>
            <Table.Cell>
            GPS Shoes
            </Table.Cell>
            <Table.Cell>
            En. Norsalehan bin Zahriman

            (820221045135)
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Muhammad Nazmi bin Mohd Hanafi (070320040399)</li>
              <li>Ammar Zulqarnain bin Mohd Shahrieel (070630040079)</li>
              <li>Muhammad Syahmi Shah bin Md Khairuddin (070417040299)</li>
              <li>Muhammad Faizuddin bin Mohd Kamluruddin (071229040245)</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            2
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Kampung Nangka
            </Table.Cell>
            <Table.Cell  >
            H&sup2;ARD
            </Table.Cell>
            <Table.Cell >
            Botol H&sup2;ARD
            </Table.Cell>
            <Table.Cell >
            Puan Jane Ling Chin

            (820221045135)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Abang Muhammad Hasyiem Bin Abang Suliman (061011130951)</li>
              <li>Muhammad Haiqal Amirul Mukminin Bin Faidzyal (060816131105)</li>
              <li>Muhd Hasrizul Nazmi Bin Mohd Jeffnee (060512130533)</li>
              <li>Rooney Garai Anak Richard Guyum (060905130921)</li>
              <li>David Wong Ka Ming (050503130313)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            3
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Sains Selangor
            </Table.Cell>
            <Table.Cell  >
            MEPCA
            </Table.Cell>
            <Table.Cell >
            HydroBoost: Revolutionizi-ng Underwater Mobility for Future Soldiers
            </Table.Cell>
            <Table.Cell >
            Puan Nurulhuda binti Che&#39;anawa

            (790113115066)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Aiman Ahza Bin Azli (080705101761)</li>
              <li>Mohammad Dhani Fathi Bin Mohammad Fairuz (080924100101) </li>
              <li>Muhammad Azfar Qusoiry Bin Mohamad Rizal (080227102187)</li>
              <li>Adreanna Nurul Husna Binti Adrean (080207100256)</li>
              <li>Marsya Zulaikha Afkar Binti Mazlan (080613140472)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            4
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Teknik Tunku Abdul Rahman Putra
            </Table.Cell>
            <Table.Cell >
            TI INNOVATION GROUP
            </Table.Cell>
            <Table.Cell>
            Senjata Bertemakan Masa Depan (Weapon of The Future)
            </Table.Cell>
            <Table.Cell>
            Puan Foo Mee Ling

            (660301075270)
            </Table.Cell>
            <Table.Cell >
            <ol className="list-decimal">
              <li>Syafi Bin Solhi (070108070937)</li>
              <li>Muhammad Zarul Naim Bin Mohd Najib Hamdan (070901030287)</li>
              <li>Muhammad Munif Bin Mukhrizal (070802101297)</li>
              <li>Adib Hafizi Bin Aminuddin (070913070403)</li>
              <li>Waldan Aiman Bin Mohd Ramli (071206080869)</li>
            </ol>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            5
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Kebangsaan Jalan Empat

            
            </Table.Cell>
            <Table.Cell>
            JESS74
            </Table.Cell>
            <Table.Cell>
            Active Defence System
            </Table.Cell>
            <Table.Cell>
            Puan Basyirah binti Ahmad

            (831107105222)

            
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Muhammad Aariz Marzuq Bin Shamsuddin (070525100623)</li>
              <li>Hafiy Eidlan Bin Shamsuddin (070707100835)</li>
              <li>Muhammad Furqan Bin Muhammad Faisal (070413101189)</li>
              <li>Darren Arulthas (070725100927)</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            6
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Bandar Tasek Mutiara

            
            </Table.Cell>
            <Table.Cell  >
            S.U.V.M.A
            </Table.Cell>
            <Table.Cell >
            Kelengkapan Tentera Masa Hadapan
            </Table.Cell>
            <Table.Cell >
            Cik Norshaliza Binti Md Lazim

            (850207075124)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Shamil Nazmi Bin Shahar (060721070063)</li>
              <li>Uwais Al-Qarni Bin Hisham (061004070639)</li>
              <li>Vigneshwaran A/L Santhiran (060429070517)</li>
              <li>Manzur Azwira Bin Irwan Firdaus (060917070129)</li>
              <li>Muhammad Airiel Hakim Bin Mohd Roslee (061005070681)</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            7
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Seksyen 16
            </Table.Cell>
            <Table.Cell  >
            GEMS OF ARES
            </Table.Cell>
            <Table.Cell >
            Portable Renewable Biomass and Multi-Ultrasonic Radar Integration System

            
            </Table.Cell>
            <Table.Cell >
            Puan Nurul Dyana Binti Ibrahim

            (890328075012)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Aidiel Izzudin Bin Mohd Zaini (061106140853)</li>
              <li>Zaim Muslihuddin Bin Mislan (060623100775)</li>
              <li>Revaathi A/P Vinggadayasan (061129141234)</li>
              <li>Muhammad Aqil Amirun Bin Mohd Zulkifli (060217030097)</li>
              <li>Nuqman Al-Qayyim bin Mohd Noor (061013100991)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            8
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Kamil

            
            </Table.Cell>
            <Table.Cell  >
            POSTERITY
            </Table.Cell>
            <Table.Cell >
            Imagining Future Soldier (PR07)
            </Table.Cell>
            <Table.Cell >
            Mohd Zukiman Bin Ismail

            (791205035367)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Siti Nur Shahidah Bt Mohd Sidek (070524030192)</li>
              <li>Amni Saafia Bt Azhar (070827030798)</li>
              <li>Muhammad Al Hafy Bin Ramzi (070624030819)</li>
              <li>Farha Ellisha Bt Zuraiman (071001030710)</li>
              <li>Nur Arabiatul Adawiyah Bt Rokeman (070823030184)</li>
            </ol>



            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            9
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Sains Sabah
            </Table.Cell>
            <Table.Cell>
            HELIX NEBULA
            </Table.Cell>
            <Table.Cell>
            Fusion D&#39;Armes
            </Table.Cell>
            <Table.Cell>
            Encik Freddy Bin Laipat

            (791118125049)
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Aleesyah Annessa Binti Juhailee (080303100192)</li>
              <li>Nadhirah Iris Irdinna Binti Mohd Hamzah (080728120682)</li>
              <li>Anis Sofea Binti Hairul Hafiz (081003121172)</li>
              <li>Jacklinwell Jikeria (080126121119)</li>
              <li>Aqil Danial Bin Mohd Hakim (080915120709)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            10
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Sains Rembau

            
            </Table.Cell>
            <Table.Cell  >
            AERSOS
            </Table.Cell>
            <Table.Cell >
            RoSOS
            </Table.Cell>
            <Table.Cell >
            Cik Syahidah Binti Mohd Bahauddin<br />
            (830212105350)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Nurhafizah Binti Mohd Farid (070731101096)</li>
              <li>Nur Aliya Safiya Binti Ruzaini (070930100344)</li>
              <li>Adriana Binti Sukhairil Arieff (071117040152)</li>
              <li>Muhammad Ezedine Bin Zubir (070923040451)</li>
              <li>Muhammad Hasrul Hakimi Bin Hasfarizal (070525050021)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            11
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Jalan Empat

            
            </Table.Cell>
            <Table.Cell  >
            ADASAN
            </Table.Cell>
            <Table.Cell >
            ADASAN JAWS-DIRAJAH
            </Table.Cell>
            <Table.Cell >
            Puan Adila Binti Md Hashim<br />
            (771110145506)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhamad Afiq Zafri Bin Mohd Zaman (080512030151)</li>
              <li>Muhammad Shamil Bin Mohd Fauzi (080627100965)</li>
              <li>Muhammad Azim Zikri Bin Mohd Azlan (080210101321)</li>
              <li>Naqib Naufal Bin Zuriawan (080704140857)</li>
              <li>Darwish Afraz Bin Razaidi (080611140823)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            12
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Permatang Pasir
            </Table.Cell>
            <Table.Cell  >
            ARAICA
            </Table.Cell>
            <Table.Cell >
            ARAICA: Heat Resistant Body Armour
            </Table.Cell>
            <Table.Cell >
            Puan Masliana Binti Sahad

            (810113015060)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Nursofea Binti Abdullah (070730070128)</li>
              <li>Nur &#39;Awatif &#39;Izzah Binti Bakhtiar. (080327020402)</li>
              <li>Auni Karmila Binti Rozaidi (070903070282)</li>
              <li>Rabiatul Adawiyah Binti Abdullah (070403070462)</li>
              <li>Miza Nur &#39;Izzah Binti Roshamizam (080917070100)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            13
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Kebangsaan Permatang Pasir
            </Table.Cell>
            <Table.Cell>
            IRENIC
            </Table.Cell>
            <Table.Cell>
            Bio-etanol alternatif kepada penghasilan tenaga boleh diperbaharui
            </Table.Cell>
            <Table.Cell>
            Puan Masliana Binti Sahad

            (810113015060)
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Nur Sofia Maisarah Binti Mohd Noor (070211070548)</li>
              <li>Nurul Niesa Imana Binti Zaim (080215101582)</li>
              <li>Muhammad Naufal Thaqif Bin Muhammad Mazalan (070612020269)</li>
              <li>Mohamad Danish Arman Bin Mohd Nasruddin (071130070153)</li>
              <li>Muhammad Aqil Bin Abdul Latif (071231070531)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            14
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Tuanku Lailatul Shahreen

            
            </Table.Cell>
            <Table.Cell  >
            TAPOPS
            </Table.Cell>
            <Table.Cell >
            SPY POT ROBOT
            </Table.Cell>
            <Table.Cell >
            Encik Noreriffin Bin Norazman

            (861021405037)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Ahmad Alimi Adli Bin Abdul Aziz (080609090177)</li>
              <li>Aqil Hatim Bin Hidayat (080614020587)</li>
              <li>Muhammad Iqbal Bin Zulkifli (081002020417)</li>
              <li>Muhammad Afif Danish Bin Ramli (080916090097)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            15
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Kampong Selamat

            
            </Table.Cell>
            <Table.Cell  >
            STRIDER X UGV
            </Table.Cell>
            <Table.Cell >
            STRIDER X UGV
            </Table.Cell>
            <Table.Cell >
            Puan Siti Suria binti Shafie

            (871101355200)

            
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Aslam Darwish Bin Mohd Suri (081108020319)</li>
              <li>Muhamad Aiman Haziq Bin Muhamed Shukri (100730021179)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            16
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Sultan Abdul Aziz
            </Table.Cell>
            <Table.Cell  >
            INNOBOT
            </Table.Cell>
            <Table.Cell >
            BOMBEKASTER
            </Table.Cell>
            <Table.Cell >
            Puan Norlisalwani binti Mohd Yaali

            (900515086012)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Nazran Bin Mohamad Mokhtar (070617080367)</li>
              <li>Muhammad Aqlan Hamiz Bin Alias (070729080409)</li>
              <li>Muhamad Tajuddin Bin Wahab (070518080089)</li>
              <li>Srenivaasan A/L Alagir Rajah (070911081133)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            17
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Tunku Abdul Rahman

            

            
            </Table.Cell>
            <Table.Cell  >
            KUMPULAN 44
            </Table.Cell>
            <Table.Cell >
            NCT (Next-Generation Command Tank)
            </Table.Cell>
            <Table.Cell >
            Mohd Fairuz bin Ismail

            (820522075055)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Heng Weien (070501020733)</li>
              <li>Tan Pei Qi (070526070454)</li>
              <li>Ahmad Aniq Abqari Bin Md Jamel (090303021039)</li>
              <li>Asyerra Syafiqah Binti Ahmad Sabri (070402080044)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            18
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Sains Rembau

            

            
            </Table.Cell>
            <Table.Cell>
            STRATAGEMS
            </Table.Cell>
            <Table.Cell>
            hyperFAC
            </Table.Cell>
            <Table.Cell>
            Syahidah binti Mohd Bahauddin

            (830212105350)
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Muhammad Aimi Zahirul Afiq Bin Mohd Aimi Zamani (070126050171)</li>
              <li>Muhammad Adham Hadif Bin Mohd Zulkifli (070304050095)</li>
              <li>Izzuiddin Putra Jumat Bin Iskandar Dzulkarnain Jumat (070222040459)</li>
              <li>Arbaati Adawiyah Binti Azmi (070115100620)</li>
              <li>Nur Haziqah Sofea Binti Mohd Azwari (070429050494)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            19
            </Table.Cell>
            <Table.Cell  >
            Sekolah Menengah Kebangsaan Agama Dato&#39; Haji Abbas

            

            
            </Table.Cell>
            <Table.Cell  >
            FI SABILILLAH
            </Table.Cell>
            <Table.Cell >
            MY model FRONTLINE
            </Table.Cell>
            <Table.Cell >
            Ahmad Nurhanif bin Hashim@Amat

            (901201115655)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Harraz Arjuna Bin Sofian (070405060137)</li>
              <li>Ahmad Syahrul Amir Bin Ahmad Khoiri (070409140513)</li>
              <li>Ahmad Shafwan Haizad Bin Mohd Kamal Rolhisham (070710110495)</li>
              <li>Adam Musyrif Bin Maswadi (070603160147)</li>
              <li>Daniel Danish Bin Noor Hisham (080407110301)</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            20
            </Table.Cell>
            <Table.Cell  >
            Maktab Tentera Diraja 

            

            
            </Table.Cell>
            <Table.Cell  >
            VIGILANCE VANGUARD

            </Table.Cell>
            <Table.Cell>
            Allart as Effective Early Warning Mechanism Based on Arduino Microcontroller for Rapid Deployment Surveillance Grid System

            
            </Table.Cell>
            <Table.Cell >
            Mohammad Ashraaf Raiez Bin Mohmmad Ridzuan

            (891007105385)
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Myzan Haqiemy Bin Muzainy (070426050309)</li>
              <li>Muhammad Haiqal Bin A. Rahman (070221050583)</li>
              <li>Mu Az Bin Sukri (070713010115)</li>
              <li>Muhammad Aqil Darwish Bin Mohd Faidzul Ezwan (070725030041)</li>
              <li>Aniq Irfan Bin Zulkarnain (070409050155)</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            21

            Sekolah Menengah Jenis Kebangsaan Chung Ling

            
            </Table.Cell>
            <Table.Cell>
            <span >HALO</span>

            </Table.Cell>
            <Table.Cell>
            The Future Soldiers
            </Table.Cell>
            <Table.Cell>
              The Better World
            </Table.Cell>
            <Table.Cell>
            <span >Puan Noorazilah </span>

            
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Wong Qing Zhen</li>
              <li>Lew Chen Jon</li>
              <li>Moh Li Wei</li>
              <li>Ooi Chun Harn</li>
              <li>Ong Ted Win</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            22
            </Table.Cell>
            <Table.Cell >
            Kolej Vokasional Tanjung Puteri
            </Table.Cell>
            <Table.Cell >
            ELECTRONIC INOVATIVE KVTP
            </Table.Cell>
            <Table.Cell >
            Robot Perubatan Autonomi Penuh (FAMR)
            </Table.Cell>
            <Table.Cell >
            Puan Nurul Syakila bt Md Shakirin
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>&nbsp;Muhammad Ilyasa Aniq Bin Mohd Meirza (060920010469)</li>
              <li>Muhammad Ashraf Bin Abdul Razak (061108011721)</li>
              <li>Muhammad Amirul Fadhil Bin Hanafiah (060415010599)</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            23
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Agama Kota Kinabalu
            </Table.Cell>
            <Table.Cell >
            STAR-CASTIC
            </Table.Cell>
            <Table.Cell >
            Capclaws
            </Table.Cell>
            <Table.Cell >
            Puan Nur Amalina Afiqah binti Amer Phadillah 
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Myasiera</li>
              <li>Aisy Daneen Umaizah Binti Redzuan</li>
              <li>Nur Elyasa Binti Suhaimi</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            24
            </Table.Cell>
            <Table.Cell >
            Maktab Melayu Kuala Kangsar 

            

            
            </Table.Cell>
            <Table.Cell >
              CYBER HEROES
            </Table.Cell>
            <Table.Cell >
            ProtecKIDS: Cyber Security Awareness Tracker for Kids 2.0

            
            </Table.Cell>
            <Table.Cell >
            Prof. Dr. Suhaily Hasnan
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Anas Rayyan bin Muhammad Shaifuddin</li>
              <li>Amir Rayyan bin Muhammad Shaifuddin</li>
            </ol>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            25
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Agama Maahad Hamidiah

            

            
            </Table.Cell>
            <Table.Cell >
            GENOFEX
            </Table.Cell>
            <Table.Cell >
            ARISTO
            </Table.Cell>
            <Table.Cell >
            Puan Noraida Binti Abdul Rahman
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Adib Bin Mohd Soffee</li>
              <li>Aina Nur Aqma Binti Khairul Anuwar</li>
              <li>Urwatul Wusqo Bin Muhammad Paiz</li>
              <li>Nur Liyana Atiqah Binti Rohadi</li>
              <li>Muhammad Abid Bin Mohd Soffee</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            26
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Datuk Haji Ahmad Badawi
            </Table.Cell>
            <Table.Cell >
            INOTECH
            </Table.Cell>
            <Table.Cell >
            INOSTRIKE-V2
            </Table.Cell>
            <Table.Cell >
            Puan Suriyani Binti Yahaya
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Fairuz bin Mohamad Fuad (070730020665)</li>
              <li>Abdul Azib Hasif bin Shahabudin (070713070521)</li>
              <li>Muhammad Adam bin Muzafar (070912080327)</li>
              <li>Muhammad Adam Ajwad bin Muhammad Nazli (070227020385)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            27
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Saujana Impian

            
            </Table.Cell>
            <Table.Cell >
            D-FALCON
            </Table.Cell>
            <Table.Cell >
            VERSA VEST
            </Table.Cell>
            <Table.Cell >
            Yuslinda Binti Yusoff
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Nur Zahirah Binti Azhar</li>
              <li>Aisyah Suhaila Binti Mohd Fuad</li>
              <li>Sofwa Qaisara Binti Mohd Shukri</li>
              <li>Oniell Rosen A/L Akash Rosen</li>
              <li>Muhamad Sufino Umair Bin Mohd Sufino Hashim</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            28
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Kebangsaan Pasir Panjang
            </Table.Cell>
            <Table.Cell>
            XPLORER TEAMZ
            </Table.Cell>
            <Table.Cell>
            Digital Arsenal
            </Table.Cell>
            <Table.Cell>
            Puan Rohaya
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Hari Krishnan Anbu A/L Chandra Mogan</li>
              <li>Paavitra A/P Yuvarajan</li>
              <li>Sivapriyankaa A/P Kehtu</li>
            </ol>

            

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            29
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Pasir Panjang
            </Table.Cell>
            <Table.Cell >
            THE FUTURE STARZ
            </Table.Cell>
            <Table.Cell >
            Captain Malaysia
            </Table.Cell>
            <Table.Cell >
            Puan Rohaya
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Kamala Kannan Anbu A/L Chandra Mogan</li>
              <li>Sangeetan A/L Veerappan</li>
              <li>Kavin Raj A/L Murugan</li>
              <li>Kamshavarthine A/P Ratha Krisnan</li>
              <li>Oviya A/P Vengadasalam</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            30
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Pasir Panjang
            </Table.Cell>
            <Table.Cell >
            YOUNG EXPLORER
            </Table.Cell>
            <Table.Cell >
            Armer Arc
            </Table.Cell>
            <Table.Cell >
            Puan Rohaya
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Sutarrsan A/L Mahendran</li>
              <li>Kasmira A/P Mohana Rangam</li>
              <li>Teiviyga Sigaa Mani A/L Masilamani</li>
              <li>Livashini A/P Ganesan</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            31
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Seksyen 18 Shah Alam

            
            </Table.Cell>
            <Table.Cell >
            FASHION IN GREEN
            </Table.Cell>
            <Table.Cell >
            Smart Handy Armor
            </Table.Cell>
            <Table.Cell >
            Puan Azmah Azmi Binti Abdul Manap
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Nur Izzaty Binti Azhar</li>
              <li>Raenia Bathresya Binti Ahmad Shafian</li>
              <li>Nur Alisha Hamizah Binti Azrim Akhtar</li>
              <li>Ammar Hakimi Bin Amin Hapis</li>
              <li>Muhammad Raziq Putra Bin Mohd Ridhwan</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            32
            </Table.Cell>
            <Table.Cell >
            Maktab Melayu Kuala Kangsar
            </Table.Cell>
            <Table.Cell >
            NOVA VIRIDI
            </Table.Cell>
            <Table.Cell >
            Development of Non-Toxic Face Paint from Microalgae as Natural Pigments and Persicaria Odorata as Natural Preservative for Defense Industry

            
            </Table.Cell>
            <Table.Cell >
            Encik Nik Mohd Azrin Bin Nik Pa
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Noor Iman Fuadi Bin Noor Faidz</li>
              <li>Jayzriq Aerif Haqim Bin Johan</li>
              <li>Radin Mizan Bin Radin Mazlan</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            33
            </Table.Cell>
            <Table.Cell >
            Kolej Tunku Kurshiah 

            
            </Table.Cell>
            <Table.Cell >
            FAVULOUS
            </Table.Cell>
            <Table.Cell >
            Formulation of Non-Hazardous and Non-Toxic Shoe Polish for Defense Industry

            

            

            
            </Table.Cell>
            <Table.Cell >
            Encik Muhammad Dzul-Harith Bin Remlle

            

            
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Noor Hannan Iftinan Binti Noor Faidz</li>
              <li>Areesa Riana Binti Ari Husni<br />
              &nbsp;</li>
            </ol>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            34
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Sains Banting

            
            </Table.Cell>
            <Table.Cell>
            MARINE SIGNAL JAMMER
            </Table.Cell>
            <Table.Cell>
            Marine Echo Jammer
            </Table.Cell>
            <Table.Cell>
            Puan Hajah Ruhani Binti Said
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Muhammad Aqil Nazmi Bin Zulzuraidi</li>
              <li>Ku Faiz Mirza Bin Ku Azhari</li>
              <li>Zahirul Iman Bin Zahidie</li>
              <li>Nur Zara Syafrina Binti Jasmi</li>
              <li>Nur Anis Binti Mohd Foudzi</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            35
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Agama Kota Kinabalu

            
            </Table.Cell>
            <Table.Cell >
            THE SOLDIERS
            </Table.Cell>
            <Table.Cell >
            Inovasi Pertahanan
            </Table.Cell>
            <Table.Cell >
            Puan Nur Amalina Afiqah binti Amer Phadilah
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Syahirah Aqilah Binti Sahruni </li>
              <li>Aisyah Nur Athirah Binti Mohd Sufri </li>
              <li>Nurul Athirah Binti Bahrunsjah </li>
            </ol>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            36
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Sains Sabah
            </Table.Cell>
            <Table.Cell >
            INVI-RESILIENT
            </Table.Cell>
            <Table.Cell >
            INVI-RESILIENT
            </Table.Cell>
            <Table.Cell >
            Encik Freddy Bin Laipat
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Mohammad Haiqal Qusyairie Bin Mohd Safawan</li>
              <li>Charlene Fellyvia Christopher</li>
              <li>Akhma Qiriah Nazwa Binti Md Mirza</li>
            </ol>


            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            37
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Bayan Lepas
            </Table.Cell>
            <Table.Cell >
            DAISY
            </Table.Cell>
            <Table.Cell >
            SPEZZER
            </Table.Cell>
            <Table.Cell >
            Puan Noor Ashikin Binti Md Desa
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Puteri Nur Damia Binti Hailmi</li>
              <li>Nur Ileeya Arisya Binti Mohd Syukri</li>
              <li>Nur Iliya Batrisya Binti Mokhtar</li>
              <li>Nur Alya Batrizsha Binti Mohd Shuhaizie</li>
              <li>Nur Farzana Binti Ibrahim</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            38
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Agama Kota Kinabalu

            
            </Table.Cell>
            <Table.Cell >
            InCR 

            (Innovative Creators)
            </Table.Cell>
            <Table.Cell >
            Virtual Rapid Glasses
            </Table.Cell>
            <Table.Cell >
            Puan Andi Kaswia Binti Abdul Kadir
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Nur Ilyana Sofia Binti Ibrahim (090418030496)</li>
              <li>Masyitah Nadhilah Binti Mohd Anuar (090923121284)</li>
              <li>Asfa Nurzayani Batrisya Binti Asti (091207120250)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            39
            
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Kebangsaan Durian Tunggal
            </Table.Cell>
            <Table.Cell >
            DUTASS BRAVO
            </Table.Cell>
            <Table.Cell >
            MINI MEDKIT
            </Table.Cell>
            <Table.Cell >
            Encik Norsalehan Bin Zahriman
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Ahmad Naufal Bin Hafizullah (070214040563)</li>
              <li>Ahmad Noramirul Azim Bin Mohd Hisham (070218101263)</li>
              <li>Adli Hafizi Bin Azmil Fathi (070519101021)</li>
              <li>Ammar Zulfadhli Bin Khairol Nizam (070525140035)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            40
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Sains Rembau 
            </Table.Cell>
            <Table.Cell >
            SLYTHERCLAW QUIDDITCH TEAM
            </Table.Cell>
            <Table.Cell >
            SNITCH THE SPY
            </Table.Cell>
            <Table.Cell >
            Cik Syahidah Binti Mohd Bahauddin
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Qistina Evelyn Leong Binti Adam Leong Kok Wey</li>
              <li>Nor Alya Sofiya Binti Zahari</li>
              <li>Ain Farisya Binti Rohaizil Azni</li>
              <li>Nurmirza Haziqah Binti Mohd Jafri</li>
              <li>Nur Hazwani Binti Azrai</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            41
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Sains Rembau
            </Table.Cell>
            <Table.Cell>
            ELYSIAN
            </Table.Cell>
            <Table.Cell>
            TRIXIAL HYDROFLY
            </Table.Cell>
            <Table.Cell>
            Encik Mozaidi bin Mokhtar

            
            </Table.Cell>
            <Table.Cell>
            1. Damien Naufal

            2. Hafiy Danish

            3. Fasya Qistina

            4. Amera Nadia

            5. Nur Nadhirah

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            42
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Sains Rembau
            </Table.Cell>
            <Table.Cell >
            ASTROGUARD
            </Table.Cell>
            <Table.Cell >
            AstroGuard: ADVANCE SPACE SUIT FOR MILITARY PERSONNEL
            </Table.Cell>
            <Table.Cell >
            Cik Syahidah Binti Mohd Bahauddin
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Irfan Hadi Bin Mazlan</li>
              <li>Muhammad Amirul Hafiz Bin M. Jefridzain</li>
              <li>Ammar Wazien Bin Abdul Wahab</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            43
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Zon R1 

            
            </Table.Cell>
            <Table.Cell >
            MIGHTY WARRIORS
            </Table.Cell>
            <Table.Cell >
            A.R.M.O. R<br />
            (Advanced Responsive Modular Helmet with Optimal Reinforcement)
            </Table.Cell>
            <Table.Cell >
            Puan Normah Binti Mispar
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Yusof Bin Deen Islam (070216101841)</li>
              <li>Ahmad Aminurahidin Bin Abdul Rais (070424140423)</li>
              <li>Muhammad Danish Asyraaf Bin Amran (071130140641)</li>
              <li>Muhammad Adam Bin Shahrinnizam (070103141089)</li>
              <li>Muhammad Afiq Bin Mohd Sukri (070108140157)</li>
            </ol>

            

            

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            44
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Sains Rembau

            
            </Table.Cell>
            <Table.Cell >
            THE RACHER
            </Table.Cell>
            <Table.Cell >
            Solar Car Panel
            </Table.Cell>
            <Table.Cell >
            Cik Syahidah Binti Mohd Bahauddin
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Nurfatin Najwa Binti Syamsul Nizam</li>
              <li>Noranisah binti Norazhar</li>
              <li>Nur Aliah Qistina binti Razali</li>
              <li>Nur Irsya binti Sapian</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            45
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Sains Rembau
            </Table.Cell>
            <Table.Cell >
            ASTRAL EXPRESS
            </Table.Cell>
            <Table.Cell>
            BIOMETRIX Energy Jelly
            </Table.Cell>
            <Table.Cell >
            Cik Syahidah Binti Mohd Bahauddin
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhaimin Arif Bin Farquehar</li>
              <li>Faris Irfan Bin Faizal</li>
              <li>Izzul Harraz Bin Asmail</li>
              <li>Muhammad Amir Adha Bin Mohd Fadzli</li>
              <li>Ameer Mikhael Bin Mohd Rizal</li>
            </ol>

            

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            46
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Sains Rembau
            </Table.Cell>
            <Table.Cell>
            THE HUJJATUL
            </Table.Cell>
            <Table.Cell>
            Nano Microdrone
            </Table.Cell>
            <Table.Cell>
            Cik Syahidah Binti Mohd Bahauddin
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Mohammad Aqil Hanif Bin Nurul Faiz</li>
              <li>Akmal Dinee Bin Jumalee</li>
              <li>Muhammad Zaid Azzurin Bin Muhamad Zaidi</li>
              <li>Muhammad Haziq Luqman Bin Mohd Azri</li>
              <li>Muhammad Rasydan Iman Bin Zulkhairin</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            47
            </Table.Cell>
            <Table.Cell >
            Sekolah Menengah Kebangsaan Bukit Rahman Putra
            </Table.Cell>
            <Table.Cell >
            THE PROTECTORS TEAM
            </Table.Cell>
            <Table.Cell>
            Enhancing Soldier&#39;s Safety: Innovative Model for Protection (Integrating Communication Tool)

            
            </Table.Cell>
            <Table.Cell >
            Cikgu Norliwa
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Vhidhya A/P Alageswaran</li>
              <li>Sarumathi A/P Sri Balamurugan</li>
            </ol>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            48
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Kebangsaan Agama Kedah
            </Table.Cell>
            <Table.Cell >
            A-FORCE
            </Table.Cell>
            <Table.Cell >
            MaCase
            </Table.Cell>
            <Table.Cell >
            Puan Farihin Bt Md Fauzi 
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Nur Nazirah Bt Salehuddin</li>
              <li>Nur Irdina Aisyah Bt Ahmad Basri</li>
              <li>Adriana Kaisa Filza Bt Abdul Manaf</li>
              <li>Anees Humaira Bt Murain</li>
              <li>Aina Imtisal Bt Amrul Fadly</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            49
            </Table.Cell>
            <Table.Cell >
            Maktab Tentera Diraja 
            </Table.Cell>
            <Table.Cell >
            THE SOLE-DIER
            </Table.Cell>
            <Table.Cell >
            Reduction of Muscle and Joint Injuries in Military Training Field Using E-Sole Impact Absorber

            
            </Table.Cell>
            <Table.Cell >
            Noor Liyana Binti Shamsudin
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Nazrin Bin Saiful Izam (070412040337)</li>
              <li>Umar Hakim Bin Syahril Lizal (071109101197)</li>
              <li>Jibreel Muhammad El-Maliki Bin Muhammad Zaki (070705030239)</li>
              <li>Mohamad Syahmi Bin Mohamad Rafee (070215050325)</li>
              <li>Muhammad Haris Aqif Bin Mohd Yusri (070326100647)</li>
            </ol>

            
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            50
            </Table.Cell>
            <Table.Cell >
            Maktab Tentera Diraja
            </Table.Cell>
            <Table.Cell >
            RMC-OILSORBERS
            </Table.Cell>
            <Table.Cell >
            A Study on Hayrigami Sheets as Mobile, Fast Deployment Post-Military Operation Oil Spillage Containment
            </Table.Cell>
            <Table.Cell >
            Noor Liyana Binti Shamsudin
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Adam Bakhtiar Bin Rosdin (070507102289)</li>
              <li>Mohamad Hafizlan Bin Masbol Mazlan (070823081221)</li>
              <li>Muhammad Fahmi Bin Shahfizal (070717080479)</li>
              <li>Muhammad Faiz Haqimy Bin Ahmad Faiezal (071027160089)</li>
              <li>Nevan Rao A/L Venggatarao Niadu (070205010581)</li>
            </ol>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            51
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Kebangsaan Jalan Empat
            </Table.Cell>
            <Table.Cell>
            M-100
            </Table.Cell>
            <Table.Cell>
            M-100
            </Table.Cell>
            <Table.Cell>
            Puan Adila Binti Md Hashim<br />
            </Table.Cell>
            <Table.Cell>
            <ol className="list-decimal">
              <li>Muhammad Hanif Idris Bin Adem Kilicman</li>
              <li>Rayyan Qawiem Bayu Bin Azzlanshah Bayu</li>
              <li>Nur Qistina Binti Yuzrinoor</li>
              <li>Aishah Binti Muhammad Ashraf</li>
              <li>Fahimah Mahirah Binti Mohd Padaliah</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            52
            </Table.Cell>
            <Table.Cell>
            Maktab Rendah Sains Mara Parit

            
            </Table.Cell>
            
            <Table.Cell>

            </Table.Cell>
            <Table.Cell >
            Conceptual Design of Smart Hijab For

            Military Purpose Through the Integration

            of Active and Passive Methods of

            Harvesting Human Body Energy

            
            </Table.Cell>
            <Table.Cell >
            Puan Raudhah Nur Evana Binti Edwin
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Muhammad Afwan Bin Ismail</li>
              <li>Nur Farrah Syazwalina Azizuddin</li>
              <li>Irfan Bin Ismail</li>
              <li>Nivashini A/P Thayalan</li>
              <li>Muhammad Ammar Hamizan Bin Hamzah</li>
            </ol>

            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white">
            <Table.Cell>
            53
            </Table.Cell>
            <Table.Cell>
            Sekolah Menengah Kebangsaan Agama Maahad Hamidiah

            
            </Table.Cell>
            <Table.Cell >
            HAZAHMAD
            </Table.Cell>
            <Table.Cell >
            Saman Exoskeleton
            </Table.Cell>
            <Table.Cell >
            Nur Amirah Binti Badruzzaman
            </Table.Cell>
            <Table.Cell  >
            <ol className="list-decimal">
              <li>Hazim Bin Erzam (101205101415)</li>
              <li>Muhammad Bin Ibrahim (100118102183)</li>
            </ol>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    <h3 className="text-white text-center mb-12">Sila muat turun jadual peserta terperinci di sini: <a className="text-[#EF901C]" href="https://docs.google.com/document/d/1P8Kb4ZIx2LR4MCzCbWRvDRnBVgylkZFX/edit?usp=sharing&ouid=111853424986330305979&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">Senarai Penyertaan.docx</a></h3>
    </section>
    <Footer/>
    </>
  )
}

export default Contestant;