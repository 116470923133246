import * as React from "react";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

import { thumbnail, slides } from "../../data/event3";


export default function GalleryEvent3() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(-1);

  return (
    <>
    <section id="accessories" className="bg-[#232323] pt-6">
      <div className="max-w-screen-lg mx-auto pt-10 pb-6 lg:px-6 sm:px-16 px-6 text-center">
        {/* <button type="button" className="bg-[#EF901C] text-white lg:text-xl text-base px-6 py-3 rounded-lg font-semibold" onClick={() => setOpen(true)}>
            Open Gallery
        </button> */}

        <h2 className="font-bold text-2xl text-center text-white mb-6">
        Sekolah Menengah Agama Persekutuan Kajang, Selangor.
        </h2>
        <PhotoAlbum
        layout="rows"
        photos={thumbnail}
        targetRowHeight={160}
        onClick={({ index: current }) => setIndex(current)}
        />

        <Lightbox
          index={index}
          slides={slides}
          open={index >= 0}
          close={() => setIndex(-1)}
          plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]} />
      </div>
    </section>

      {/* <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={advancedSlides}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      /> */}

    </>
  );
}