export const slides = [
  {
    title: "Sekolah Sultan Alam Shah Putrajaya-(1).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/4_jpg/okt-(1).jpg'
  },
  {
    title: "Sekolah Sultan Alam Shah Putrajaya-(2).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/4_jpg/okt-(2).jpg'
  },
  {
    title: "Sekolah Sultan Alam Shah Putrajaya-(3).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/4_jpg/okt-(3).jpg'
  },
  {
    title: "Sekolah Sultan Alam Shah Putrajaya-(4).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/4_jpg/okt-(4).jpg'
  },
  {
    title: "Sekolah Sultan Alam Shah Putrajaya-(5).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/4_jpg/okt-(5).jpg'
  },
];

export const thumbnail = [
  {
    src: '/gallery/4_jpg/okt-(1).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/4_jpg/okt-(2).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/4_jpg/okt-(3).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/4_jpg/okt-(4).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/4_jpg/okt-(5).jpg',
    width: 1280,
    height: 720,
  },
];