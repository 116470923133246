import { Fade } from "react-reveal";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import Gallery from "./Gallery";


function MetaQuest() {
  return (
    <>
    <Nav/>
    <section id="accessories" className="bg-[#232323] border-b-[4px] border-[#EF901C] pt-24">
      <div className="max-w-screen-lg mx-auto py-16 lg:px-16 sm:px-16 px-6 ">
        <div className="grid grid-cols-12 lg:gap-x-4 lg:gap-y-4 sm:gap-4 gap-3">
          <Fade left>
          <div className="sm:col-span-4 col-span-12 lg:h-72 overflow-hidden lg:rounded-3xl rounded-xl">
            <img  src={`/quest-baru-01.png`} alt="Meta quest 1" className="h-full"/>
          </div>
          </Fade>
          <Fade left>
          <div className="sm:col-span-8 col-span-12 lg:h-72 overflow-hidden lg:rounded-3xl rounded-xl">
            <img src={`/quest-baru-02.png`} alt="Meta quest 2" className="h-full"/>
          </div>
          </Fade>
          <Fade left>
          <div className="col-span-5 row-span-2 lg:pr-0 lg:pt-0">
            <img src={`/quest-baru-03.png`} alt="Meta Quest 3" className="h-full"/>
          </div>
          </Fade>
          <Fade left>
          <div className="col-span-7 lg:pt-0  ">
            <h2 className="text-white lg:text-6xl sm:text-5xl text-2xl font-bold">META QUEST 2</h2>
            <h2 className=" text-[#EF901C] lg:text-6xl sm:text-5xl text-2xl lg:mt-0 sm:-mt-1 -mt-2 font-bold">GUNSTOCK</h2>
          </div>
          </Fade>
          <Fade left>
          <div className="col-span-7">
            <img src={`/quest-baru-04.png`} alt="Meta Quest 4" className="h-full"/>
          </div>
          </Fade>
        </div>
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default MetaQuest;