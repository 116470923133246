import { Fade } from "react-reveal";
function About() {
  return (
    <section id="Hero" className="bg-[#232323] border-b-[4px] border-[#EF901C] overflow-hidden">
      <div className="max-w-screen-xl mx-auto lg:py-36 py-10 lg:my-0 sm:my-4 my-12 ">
        <div className="grid grid-cols-12 lg:pb-20 relative">
          <Fade left cascade>
          <img src={`/about-01.png`} alt="About Thumbnail - Tentara Stride" className="absolute max-w-[40rem] lg:pl-12 pl-0 top-0 left-0 h-full"/>
          </Fade>
          <div className="col-span-5 sm:block hidden"></div>
          <Fade right cascade>
          <div className="sm:col-span-7 col-span-10 lg:px-0 px-6 z-20">
            <h2 className="text-[#EF901C] text-4xl font-medium">THE</h2>
            <h2 className="text-white lg:text-[5rem] text-4xl leading-[1] lg:font-extrabold font-bold">FUTURE SOLDIERS</h2>
            <p
              className="text-white lg:text-md text-sm lg:mt-10 mt-4 lg:leading-[1.75] leading-[1.5] font-semibold">
              "The STRIDE (Science & Technology Research Institute For Defence) program stands as a testament to Malaysia's unwavering commitment to shaping the future of its armed forces. Fueled by cutting-edge technology and forward-thinking research, STRIDE envisions a new era of military prowess, where innovation becomes the cornerstone of Malaysia's defense strategy.
              <br/> <br/>
              At its core, STRIDE is an ambitious initiative that seeks to harness the latest advancements in technology to equip the Malaysian soldier with unmatched capabilities. This entails the integration of artificial intelligence, advanced materials, and next-level communication systems into every facet of a soldier's mission. From enhanced situational awareness through AI-driven analytics to lightweight yet robust exoskeletons that amplify physical strength, the future soldier under STRIDE's banner embodies a fusion of human skill and technological supremacy."
            </p>
          </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}

export default About;