export const slides = [
  {
    title: "Sekolah Menengah Agama Persekutuan Kajang-(1).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/3_jpg/sept-(1).jpg'
  },
  {
    title: "Sekolah Menengah Agama Persekutuan Kajang-(2).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/3_jpg/sept-(2).jpg'
  },
  {
    title: "Sekolah Menengah Agama Persekutuan Kajang-(3).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/3_jpg/sept-(3).jpg'
  },
  {
    title: "Sekolah Menengah Agama Persekutuan Kajang-(4).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/3_jpg/sept-(4).jpg'
  },
  {
    title: "Sekolah Menengah Agama Persekutuan Kajang-(5).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/3_jpg/sept-(5).jpg'
  },
  {
    title: "Sekolah Menengah Agama Persekutuan Kajang-(6).jpg",
    description:'',
    width: 1280,
    height: 720,
    src: '/gallery/3_jpg/sept-(6).jpg'
  },
];

export const thumbnail = [
  {
    src: '/gallery/3_jpg/sept-(1).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/3_jpg/sept-(2).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/3_jpg/sept-(3).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/3_jpg/sept-(4).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/3_jpg/sept-(5).jpg',
    width: 1280,
    height: 720,
  },
  {
    src: '/gallery/3_jpg/sept-(6).jpg',
    width: 1280,
    height: 720,
  },
];